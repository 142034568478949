import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Carousel from "../components/Carousel.js"
import rehypeReact from "rehype-react"
import {
  Typography,
  Link,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  styled,
  Button,
} from "@material-ui/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductItem from "../components/product-item"
import PageItem from "../components/page-item"
import { AddToBasket } from "../components/basket-buttons"
import {
  AccessTimeOutlined,
  AccountBalanceOutlined,
  AccountBalanceWalletOutlined,
  AddCircleOutlineOutlined,
  BeenhereOutlined,
  BusinessOutlined,
  Email,
  InfoOutlined,
  LocalShippingOutlined,
  MoneyOutlined,
  Phone,
  ShoppingCartOutlined,
  WeekendOutlined,
} from "@material-ui/icons"
import udnImage from "../../content/assets/udn-img.png"
import cogFilled from "../../content/assets/cog-filled.svg"

const localeNumberOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h1: props => <Typography component="h1" variant="h5" {...props} />,
    h2: props => <Typography component="h2" variant="h6" {...props} />,
    h3: props => <Typography component="h3" variant="subtitle1" {...props} />,
    h4: props => (
      <Typography component="h4" variant="caption" paragraph {...props} />
    ),
    p: props => <Typography paragraph variant="h6" {...props} />,
    a: Link,
    li: props => (
      <li>
        <Typography component="span" {...props} />
      </li>
    ),
    table: props => (
      <Table
        style={{
          overflow: "auto",
          display: "block",
          backgroundColor: "transparent",
          width: "auto",
          margin: "1%",
          borderRadius: "2px",
          boxShadow: "1px 1px 6px rgba(0,0,0,.1)",
        }}
      >
        {props.children}
      </Table>
    ),
    thead: props => (
      <TableHead style={{ backgroundColor: "#252525", color: "#fff" }}>
        {props.children}
      </TableHead>
    ),
    tbody: props => (
      <TableBody style={{ backgroundColor: "#fff" }}>
        {props.children}
      </TableBody>
    ),
    tr: props => <StyledTableRow>{props.children}</StyledTableRow>,
    th: props => (
      <TableCell style={{ width: "1%", padding: "12px", color: "#fff" }}>
        {props.children}
      </TableCell>
    ),
    td: props => (
      <TableCell style={{ padding: "10px" }}>{props.children}</TableCell>
    ),
  },
}).Compiler

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}))

const StyledImage = styled(Img)(({ theme }) => ({
  objectFit: "contain",
  "& > img": { objectFit: "contain !important" },
  "& > picture": { "& > img": { objectFit: "contain !important" } },
}))

const useStyles = makeStyles(theme => ({
  productimage: {
    margin: 5,
    transition: "all .3s ease-in-out",
    [theme.breakpoints.up("md")]: {
      maxHeight: "80vh",
    },
  },
  discount: {
    position: "relative",
    width: "100%",
    background:
      "radial-gradient(circle, rgba(73,73,73,1) 0%, rgba(62,62,62,1) 25%, rgba(54,54,54,1) 50%, rgba(42,42,42,1) 75%, rgba(0,0,0,1) 100%)",
    color: "#fff",
    fontSize: "1.4rem",
    alignItems: "center",
    justifyContent: "space-around",
    display: "inline-flex",
    borderRadius: "2px",
  },
  extraDiscountAlert: {
    border: "1px solid #60cdff",
    borderRadius: "2%",
    backgroundColor: "#b5e8ff",
  },
  specialDealAlert: {
    border: "1px solid #ff0000",
    borderRadius: "2%",
    backgroundColor: "#1f1f1f",
    color: "#fff",
  },
  productimages: {
    borderRadius: 2,
    transition: "all .3s ease-in-out",
  },
  productimages123: {
    overflow: "hidden",
    padding: 5,
    width: "100%",
    height: "100%",
    transition: "all .2s ease-in-out",
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        boxShadow: "1px 1px 6px rgba(0,0,0,.3)",
        borderRadius: "2px",
      },
    },
  },
  productimagecontainer: {
    position: "relative",
    width: "100%",
    alignItems: "center",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
      padding: "5px",
    },
  },
  productimagesgrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    gap: "5px",
    padding: "2px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      maxHeight: "80vh",
    },
  },
  pageItem: {
    backgroundColor: "#fff",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  pageItemText: {
    backgroundColor: "#fff",
    color: "#000",
    fontWeight: 900,
    textTransform: "uppercase",
    fontSize: "45px",
    marginTop: "30px",
    marginBottom: 0,
    fontStyle: "oblique",
    lineHeight: "1",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0.5),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(20),
      paddingRight: theme.spacing(20),
      fontSize: "60px",
      marginTop: "50px",
      marginBottom: 15,
    },
  },
  infoContainer: {
    marginTop: "5vh",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap !important",
    },
  },
  infoGrid: {
    display: "grid",
    placeContent: "center",
    placeItems: "center",
    placeSelf: "start",
    backgroundColor: "#f5f5f5",
    padding: "5px",
    margin: "5px",
    borderRadius: "2px",
    alignContent: "stretch !important",
    alignSelf: "stretch !important",
  },
  infoHeading: {
    borderRadius: "2px",
    margin: "5px",
    padding: "5px",
    color: "white",
    background:
      "radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(54,54,54,1) 0%, rgba(0,0,0,1) 100%)",
    fontSize: "16px",
    width: "100%",
    textTransform: "uppercase",
  },
  infoText: {
    paddingLeft: "2%",
    paddingRight: "2%",
    fontSize: "14px",
  },
  iconWebshop: {
    position: "absolute",
    color: "rgba(51,51,51,.05)",
    fontSize: "200px",
  },
  iconOrder: {
    position: "absolute",
    color: "rgba(51,51,51,.05)",
    fontSize: "200px",
  },
  iconShipping: {
    position: "absolute",
    color: "rgba(51,51,51,.05)",
    fontSize: "200px",
  },
  dislaimer: {
    backgroundColor: "#000",
    color: "#919191",
    width: "100%",
    fontSize: "11px",
    paddingLeft: "15%",
    paddingRight: "15%",
    paddingTop: "5px",
  },
  dislaimerEuro: {
    backgroundColor: "#000",
    color: "#919191",
    width: "100%",
    fontSize: "14px",
    fontWeight: "bold",
    paddingLeft: "15%",
    paddingRight: "15%",
    paddingTop: "5px",
  },
  productListGrid: {
    backgroundColor: "#fafafa",
  },
  imgGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    marginTop: "3%",
    marginBottom: "5%",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
      marginLeft: "1%",
      marginRight: "1%",
    },
  },
  contentGrid: {
    [theme.breakpoints.up("md")]: {
      // paddingLeft: "15%",
      // paddingRight: "15%",
    },
  },
  priceText: {
    [theme.breakpoints.up("md")]: {
      fontSize: "25px",
    },
  },
  priceEuro: {
    fontWeight: "900",
    textAlign: "left",
    paddingLeft: "2%",
    fontSize: "10vw",
    marginBottom: 0,
    paddingBottom: 15,
    lineHeight: 1,
    [theme.breakpoints.up("md")]: {
      fontSize: "3vw",
    },
  },
  extraDiscouuntPrice: {
    fontWeight: 900,
    paddingLeft: "2%",
    paddingRight: "2%",
    textAlign: "center",
    fontSize: "3vh",
  },
  extraDiscountText: {
    padding: "2%",
    fontSize: "1.7vh",
  },
  specialDealText: {
    padding: "2%",
    fontSize: "1.7vh",
  },
  emblemText: {
    padding: "2%",
    fontSize: "2vh",
    backgroundColor: "#ff0000",
    color: "#fff",
    borderRadius: "2%",
    textAlign: "center",
    fontWeight: 900,
    textTransform: "uppercase",
    letterSpacing: "5px",
  },
  title: {
    paddingTop: "5%",
    paddingBottom: "0",
    padding: "1%",
    color: "#000",
    fontSize: "6vw !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4vw !important",
    },
  },
  sku: {
    paddingTop: "0",
    paddingBottom: "5%",
    padding: "1%",
    color: "#000",
    fontSize: "3vw !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.7vw !important",
    },
  },
  priceGrid: {
    marginBottom: "3%",
  },
  underPriceText: {
    marginBottom: "3%",
  },
  pricetagGrid: {
    // alignSelf: "center",
  },
  pricetag: {
    color: "#000",
    borderRadius: "2px",
    padding: 5,
  },
  priceBef: {
    color: "#808080",
  },
  priceBef1: {
    color: "#808080",
    fontSize: "12px",
  },
  priceBeforeText: {
    color: "#808080",
    [theme.breakpoints.up("md")]: {
      fontSize: "15px",
    },
  },
  priceBeforeEuroGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  priceBeforeEuro: {
    textDecoration: "line-through",
    fontWeight: "400",
    textAlign: "left",
    paddingLeft: "2%",
    fontSize: "5vw",
    color: "#808080",
    [theme.breakpoints.up("md")]: {
      fontSize: "1vw",
    },
  },
  availableGrid: {
    display: "flex",
    alignItems: "center",
    padding: "2%",
    marginBottom: "2%",
  },
  sizesMainDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  sizeDiv: {
    backgroundColor: "#abffab",
    color: "#000",
    fontWeight: "900",
    borderRadius: "2px",
    margin: "1%",
    marginLeft: "2%",
    marginRight: "2%",
    width: "50px",
    textAlign: "center",
    padding: "2%",
    boxShadow: "1px 1px 2px rgba(0,0,0,.5)",
  },
  sizeDivOrderable: {
    backgroundColor: "#ffda7a",
    color: "#000",
    fontWeight: "900",
    borderRadius: "2px",
    margin: "2%",
    marginLeft: "2%",
    marginRight: "2%",
    minWidth: "55px",
    textAlign: "center",
    padding: "2%",
    boxShadow: "1px 1px 2px rgba(0,0,0,.5)",
  },
  sizesHeading: {
    fontWeight: "900",
  },
  orderableTime: {
    textAlign: "center",
    fontSize: "12px",
    padding: 0,
    margin: 0,
  },
  sizeTableGrid: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "10%",
      paddingRight: "10%",
    },
  },
  sizeTableHeading: {
    textAlign: "left",
    fontWeight: "900 !important",
    padding: "1%",
    fontSize: "1.25rem",
    paddingBottom: "0",
    textTransform: "uppercase",
  },
  sizeTable: {
    overflow: "auto",
    display: "block",
    width: "auto",
    margin: "1%",
    borderRadius: "2px",
    boxShadow: "1px 1px 6px rgba(0,0,0,.1)",
  },
  sizeTableHead: {
    backgroundColor: "#252525",
    whiteSpace: "nowrap",
  },
  sizeTableHeadCell: {
    color: "#fff",
    fontWeight: "900",
    textTransform: "uppercase",
  },
  sizeTableBody: {
    backgroundColor: "#f7f7f7",
  },
  sizeTableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
  },
  overlay: {
    position: "absolute",
    top: "2%",
    right: "2%",
    color: "#fff",
    background: `url(${cogFilled})`,
    width: "8vh",
    height: "8vh",
    fontSize: "1.2rem",
    transform: "rotate(-20deg)",
    alignItems: "center",
    justifyContent: "center",
    display: "inline-flex",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "rotate(-2deg)",
    },
    [theme.breakpoints.up("md")]: {
      top: "5%",
      right: "5%",
      width: "10vh",
      height: "10vh",
      fontSize: "1.6rem",
    },
  },
  overlay1: {
    position: "relative",
    color: "#fff",
    backgroundColor: "#252525",
    borderRadius: "2px",
    fontSize: "5vw",
    fontWeight: 600,
    padding: "5%",
    paddingLeft: "8%",
    paddingRight: "8%",
    transform: "rotate(-20deg)",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "rotate(-2deg)",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1vw",
    },
  },
  emblemTextOnImage: {
    position: "absolute",
    backgroundColor: "#000",
    color: "#fff",
    border: "2px solid #ff0000",
    borderRadius: "2%",
    padding: "0.5%",
    alignItems: "center",
    justifyContent: "center",
    top: "2%",
    left: "2%",
    maxwidth: "25%",
    minWidth: "5%",
    fontSize: "0.8rem",
    display: "inline-flex",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px",
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      top: "5%",
      left: "25%",
      fontSize: "1.3rem",
      letterSpacing: "3px",
    },
  },
  illText: {
    padding: "1%",
    paddingLeft: "2%",
    paddingRight: "2%",
  },
  illGridItem: {
    padding: "0",
  },
  illUnorderedList: {
    listStyle: "none",
    marginTop: "1%",
  },
  illMainGrid: {
    paddingTop: "10%",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      paddingTop: "2%",
    },
  },
  illList: {
    padding: "1%",
    marginTop: "1%",
    marginBottom: "1%",
    fontSize: ".95rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    "&::before": {
      position: "relative",
      marginLeft: "-12%",
      content: '"1"',
      backgroundColor: "#252525",
      color: "#fff",
      borderRadius: "2px",
      alignItems: "center",
      justifyContent: "center",
      width: "1%",
      marginRight: "2%",
      padding: "3%",
      [theme.breakpoints.up("md")]: {
        margin: "2%",
        padding: "1%",
      },
    },
    "&:nth-child(1):before": {
      content: '"1"',
    },
    "&:nth-child(2):before": {
      content: '"2"',
    },
    "&:nth-child(3):before": {
      content: '"3"',
    },
    "&:nth-child(4):before": {
      content: '"4"',
    },
    "&:nth-child(5):before": {
      content: '"5"',
    },
  },
  crsl: {
    width: "100%",
    height: "100%",
  },
  checkGrid: {
    padding: "1%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.up("lg")]: {
      flexWrap: "nowrap",
    },
  },
  checkGridText: {
    fontSize: "1.5vh",
    padding: "1%",
    whiteSpace: "nowrap",
  },
  checkIconGrid: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  phoneButton: {
    backgroundColor: "#000",
    color: "#fff",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#252525",
      color: "#fff",
      border: "1px solid #000",
    },
  },
  emailButton: {
    backgroundColor: "#000",
    color: "#fff",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#252525",
      color: "#fff",
      border: "1px solid #000",
    },
  },
  notice: {
    marginTop: "15vh",
    alignSelf: "center",
    position: "absolute",
    zIndex: "1",
    color: "#fff",
    backgroundColor: "rgba(0,0,0,0.5)",
    paddingLeft: "5vh",
    paddingRight: "5vh",
    paddingBottom: "2vh",
    paddingTop: "2vh",
    fontWeight: 900,
    positionArea: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  noticeIcon: {
    fontSize: "4vh",
  },
}))

const PageTemplate = props => {
  const classes = useStyles()
  const post = props.data.markdownRemark
  const subpages = props.data.allMarkdownRemark.nodes.filter(
    x => x.frontmatter.price === null
  )
  const products = props.data.allMarkdownRemark.nodes.filter(
    x => x.frontmatter.price !== null
  )
  const siteTitle = props.data.site.siteMetadata.title

  const product = {
    sku: post.frontmatter.sku,
    last30days: post.frontmatter.last30days,
    available: post.frontmatter.available,
    orderable: post.frontmatter.orderable,
    sizeTableMTB: post.frontmatter.sizeTableMTB,
    sizeTableCTB: post.frontmatter.sizeTableCTB,
    title: post.frontmatter.title,
    slug: post.fields.slug,
    price: post.frontmatter.price,
    extraDiscount: post.frontmatter.extraDiscount,
    specialDeal: post.frontmatter.specialDeal,
    image: post.frontmatter.image.childImageSharp.fluid.srcSet,
    brand: post.frontmatter.brand,
    // cardPrice: post.frontmatter.cardPrice,
    quantity: 1,
  }

  return (
    <Layout title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={
          post.frontmatter.image
            ? post.frontmatter.image.childImageSharp.fluid.src
            : ""
        } // default slika
      />

      {/* Product */}

      {post.frontmatter.price && (
        <>
          <Grid container spacing={0}>
            {/* Product image */}
            <Grid
              md={12}
              style={{ height: "12vh", background: "#000", width: "100%" }}
            ></Grid>
            <Grid md={12} className={classes.imgGrid}>
              <Grid item md={9} xs={12}>
                <Grid
                  container
                  spacing={0}
                  className={classes.productimagecontainer}
                >
                  <Grid
                    item
                    xs={12}
                    md={9}
                    className={classes.productImageFlex}
                  >
                    {post.frontmatter.image && (
                      <a
                        href={post.frontmatter.image.publicURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <StyledImage
                          className={classes.productimage}
                          fluid={post.frontmatter.image.childImageSharp.fluid}
                        />
                        {post.frontmatter.priceBefore &&
                          !post.frontmatter.specialDeal && (
                            <span className={classes.overlay}>
                              {Math.round(
                                ((post.frontmatter.price -
                                  post.frontmatter.priceBefore) /
                                  post.frontmatter.priceBefore) *
                                  100
                              )}
                              %
                            </span>
                          )}
                        {post.frontmatter.priceBefore &&
                          post.frontmatter.specialDeal && (
                            <span className={classes.overlay}>
                              {Math.round(
                                ((((100 - post.frontmatter.specialDeal) / 100) *
                                  post.frontmatter.price -
                                  post.frontmatter.priceBefore) /
                                  post.frontmatter.priceBefore) *
                                  100
                              )}
                              %
                            </span>
                          )}
                        {post.frontmatter.emblem && (
                          <span className={classes.emblemTextOnImage}>
                            {post.frontmatter.emblem}
                          </span>
                        )}
                      </a>
                    )}
                  </Grid>
                  <Grid xs={12} md={3} className={classes.productimagesgrid}>
                    {post.frontmatter.image1 && (
                      <Grid
                        item
                        xs={4}
                        md={10}
                        className={classes.productimages123}
                      >
                        <a
                          href={post.frontmatter.image1.publicURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="image1"
                        >
                          <Img
                            className={classes.productimages}
                            fluid={
                              post.frontmatter.image1.childImageSharp.fluid
                            }
                          />
                        </a>
                      </Grid>
                    )}
                    {post.frontmatter.image2 && (
                      <Grid
                        item
                        xs={4}
                        md={10}
                        className={classes.productimages123}
                      >
                        <a
                          href={post.frontmatter.image2.publicURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="image2"
                        >
                          <Img
                            className={classes.productimages}
                            fluid={
                              post.frontmatter.image2.childImageSharp.fluid
                            }
                          />
                        </a>
                      </Grid>
                    )}
                    {post.frontmatter.image3 && (
                      <Grid
                        item
                        xs={4}
                        md={10}
                        className={classes.productimages123}
                      >
                        <a
                          href={post.frontmatter.image3.publicURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="image3"
                        >
                          <Img
                            className={classes.productimages}
                            fluid={
                              post.frontmatter.image3.childImageSharp.fluid
                            }
                          />
                        </a>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Product title and SKU */}

              <Grid item md={3} xs={12} className={classes.pricetagGrid}>
                <Typography
                  align="left"
                  component="h1"
                  className={classes.title}
                >
                  {post.frontmatter.title}
                </Typography>
                {post.frontmatter.sku && (
                  <Typography className={classes.sku} gutterBottom>
                    Šifra proizvoda: <b>{post.frontmatter.sku}</b>
                  </Typography>
                )}

                {/* Product price */}

                <Grid
                  container
                  spacing={0}
                  gutterBottom
                  className={classes.pricetag}
                >
                  {post.frontmatter.price && (
                    <Grid
                      container
                      xs={12}
                      md={12}
                      className={classes.priceGrid}
                    >
                      <Grid item xs={12} md={12}>
                        {post.frontmatter.priceBefore && (
                          <Grid
                            xs={12}
                            md={12}
                            className={classes.priceBeforeEuroGrid}
                          >
                            <>
                              <Grid item xs={9} md={9}>
                                <Typography className={classes.priceBeforeEuro}>
                                  {post.frontmatter.priceBefore.toLocaleString(
                                    "hr-HR",
                                    localeNumberOptions
                                  )}{" "}
                                  €
                                </Typography>
                              </Grid>

                              <Grid item xs={3} md={3}>
                                <span className={classes.overlay1}>
                                  {Math.round(
                                    ((post.frontmatter.price -
                                      post.frontmatter.priceBefore) /
                                      post.frontmatter.priceBefore) *
                                      100
                                  )}
                                  %
                                </span>
                              </Grid>
                            </>
                          </Grid>
                        )}
                        <Typography gutterBottom className={classes.priceEuro}>
                          {post.frontmatter.price.toLocaleString(
                            "hr-HR",
                            localeNumberOptions
                          )}{" "}
                          €
                        </Typography>
                        {post.frontmatter.extraDiscount && (
                          <Grid className={classes.extraDiscountAlert}>
                            <Typography className={classes.extraDiscountText}>
                              Na ovaj proizvod se obračunava dodatan popust od{" "}
                              <b>{post.frontmatter.extraDiscount}%</b> za
                              jednokratno plaćanje gotovinom (novčanicama) i
                              transakcijskim računom (uplata na račun):
                            </Typography>
                            <Typography
                              gutterBottom
                              className={classes.extraDiscouuntPrice}
                            >
                              {Math.abs(
                                ((100 - post.frontmatter.extraDiscount) / 100) *
                                  post.frontmatter.price
                              ).toLocaleString(
                                "hr-HR",
                                localeNumberOptions
                              )}{" "}
                              €
                            </Typography>
                          </Grid>
                        )}
                        {post.frontmatter.specialDeal && (
                          <Grid className={classes.specialDealAlert}>
                            {post.frontmatter.emblem && (
                              <Typography className={classes.emblemText}>
                                {post.frontmatter.emblem}
                              </Typography>
                            )}
                            <Typography className={classes.specialDealText}>
                              Dodatan popust od{" "}
                              <b>{post.frontmatter.specialDeal}%</b> za
                              jednokratno plaćanje gotovinom (novčanicama) i
                              transakcijskim računom (uplata na račun):
                            </Typography>
                            <Typography
                              gutterBottom
                              className={classes.extraDiscouuntPrice}
                            >
                              {Math.abs(
                                ((100 - post.frontmatter.specialDeal) / 100) *
                                  post.frontmatter.price
                              ).toLocaleString(
                                "hr-HR",
                                localeNumberOptions
                              )}{" "}
                              €
                            </Typography>
                            <Typography className={classes.specialDealText}>
                              Dodatan popust od{" "}
                              <b>
                                {Math.abs(post.frontmatter.specialDeal - 5)} %
                              </b>{" "}
                              za plaćanje karticama na rate:
                            </Typography>
                            <Typography
                              gutterBottom
                              className={classes.extraDiscouuntPrice}
                            >
                              {Math.abs(
                                ((100 - post.frontmatter.specialDeal + 5) /
                                  100) *
                                  post.frontmatter.price
                              ).toLocaleString(
                                "hr-HR",
                                localeNumberOptions
                              )}{" "}
                              €
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    xs={12}
                    md={12}
                    className={classes.underPriceText}
                  >
                    {post.frontmatter.priceBefore && (
                      <Grid item xs={12} md={12}>
                        <Typography
                          align="left"
                          className={classes.priceBef1}
                          variant="subtitle2"
                        >
                          Preporučena maloprodajna cijena proizvođača:{" "}
                          {post.frontmatter.priceBefore.toLocaleString(
                            "hr-HR",
                            localeNumberOptions
                          )}{" "}
                          €
                        </Typography>
                        {!post.frontmatter.specialDeal && (
                          <Typography
                            align="left"
                            className={classes.priceBef1}
                            variant="subtitle2"
                          >
                            Ušteda (
                            {Math.round(
                              ((post.frontmatter.priceBefore -
                                post.frontmatter.price) /
                                post.frontmatter.priceBefore) *
                                100
                            )}
                            %): -
                            {Math.abs(
                              post.frontmatter.priceBefore -
                                post.frontmatter.price
                            ).toLocaleString("hr-HR", localeNumberOptions)}{" "}
                            €
                          </Typography>
                        )}
                        {post.frontmatter.specialDeal && (
                          <Typography
                            align="left"
                            className={classes.priceBef1}
                            variant="subtitle2"
                          >
                            Ušteda (
                            {Math.round(
                              ((((100 - post.frontmatter.specialDeal) / 100) *
                                post.frontmatter.price -
                                post.frontmatter.priceBefore) /
                                post.frontmatter.priceBefore) *
                                100
                            )}
                            %): -
                            {Math.abs(
                              post.frontmatter.priceBefore -
                                ((100 - post.frontmatter.specialDeal) / 100) *
                                  post.frontmatter.price
                            ).toLocaleString("hr-HR", localeNumberOptions)}{" "}
                            €
                          </Typography>
                        )}
                        {!post.frontmatter.last30days && (
                          <Typography
                            align="left"
                            className={classes.priceBef1}
                            variant="subtitle2"
                          >
                            Najniža cijena u posljednjih 30 dana:{" "}
                            {post.frontmatter.price.toLocaleString(
                              "hr-HR",
                              localeNumberOptions
                            )}{" "}
                            €
                          </Typography>
                        )}
                        {post.frontmatter.last30days && (
                          <Typography
                            align="left"
                            className={classes.priceBef1}
                            variant="subtitle2"
                          >
                            Najniža cijena u posljednjih 30 dana:{" "}
                            {post.frontmatter.last30days.toLocaleString(
                              "hr-HR",
                              localeNumberOptions
                            )}{" "}
                            €
                          </Typography>
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12} md={12}>
                      <Typography
                        align="left"
                        className={classes.priceBef1}
                        variant="subtitle2"
                      >
                        U cijenu je uračunat PDV.
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Bike sizes */}

                  {post.frontmatter.available &&
                    post.frontmatter.available.length && (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.availableGrid}
                      >
                        <Grid item xs={12} md={4}>
                          <p className={classes.sizesHeading}>
                            Dostupno na lageru:
                          </p>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <div className={classes.sizesMainDiv}>
                            {post.frontmatter.available.map(s => (
                              <td className={classes.sizeDiv}>{s}</td>
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  {post.frontmatter.orderable &&
                    post.frontmatter.orderable.length && (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.availableGrid}
                      >
                        <Grid item xs={12} md={4}>
                          <p className={classes.sizesHeading}>
                            Dobavljivo (naručivanje):
                          </p>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <div className={classes.sizesMainDiv}>
                            {post.frontmatter.orderable.map(s => (
                              <td className={classes.sizeDivOrderable}>{s}</td>
                            ))}
                          </div>
                          <p className={classes.orderableTime}>
                            *Dobavljivo kroz 10ak radnih dana.
                          </p>
                        </Grid>
                      </Grid>
                    )}
                  <Grid item xs={12} md={12} className={classes.checkGrid}>
                    <Typography className={classes.checkGridText}>
                      Provjeri stanje u poslovnici:
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.checkIconGrid}
                    >
                      <Button
                        variant="outlined"
                        href="tel:+38517888860"
                        startIcon={<Phone />}
                        className={classes.phoneButton}
                      >
                        Poziv
                      </Button>
                      <Button
                        variant="outlined"
                        href={`mailto:info@ciklo-sport.hr?subject=Upit%20o%20dostupnosti%20${post.frontmatter.title}`}
                        startIcon={<Email />}
                        className={classes.emailButton}
                      >
                        e-mail
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Product content */}

            <Grid item xs={12} md={12} className={classes.contentGrid}>
              {renderAst(post.htmlAst)}
            </Grid>

            {/* Bike size picker tables */}

            {post.frontmatter.sizeTableCTB && (
              <Grid item xs={12} md={12} className={classes.sizeTableGrid}>
                <Typography className={classes.sizeTableHeading}>
                  Odabir veličine: {post.frontmatter.sizeTableCTB}
                </Typography>
                <Table className={classes.sizeTable}>
                  <TableHead className={classes.sizeTableHead}>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Visina vozača
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Unutarnja duljina nogu
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Rove
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Libre
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Sutra
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Dew
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Coco
                    </TableCell>
                  </TableHead>
                  <TableBody className={classes.sizeTableBody}>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>147-155cm</TableCell>
                      <TableCell>61-69cm</TableCell>
                      <TableCell>48cm</TableCell>
                      <TableCell>50cm</TableCell>
                      <TableCell>48cm</TableCell>
                      <TableCell>Small</TableCell>
                      <TableCell>XS</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>152-160cm</TableCell>
                      <TableCell>66-74cm</TableCell>
                      <TableCell>48-50cm</TableCell>
                      <TableCell>50cm</TableCell>
                      <TableCell>48-50cm</TableCell>
                      <TableCell>Small</TableCell>
                      <TableCell>Small</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>157-170cm</TableCell>
                      <TableCell>69-76cm</TableCell>
                      <TableCell>48-52cm</TableCell>
                      <TableCell>50-52cm</TableCell>
                      <TableCell>48-52cm</TableCell>
                      <TableCell>Small, Medium</TableCell>
                      <TableCell>Small, Medium</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>168-178cm</TableCell>
                      <TableCell>74-79cm</TableCell>
                      <TableCell>52-54cm</TableCell>
                      <TableCell>52-54cm</TableCell>
                      <TableCell>52-54cm</TableCell>
                      <TableCell>Medium</TableCell>
                      <TableCell>Medium</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>175-183cm</TableCell>
                      <TableCell>76-84cm</TableCell>
                      <TableCell>52-56cm</TableCell>
                      <TableCell>54-56cm</TableCell>
                      <TableCell>54-56cm</TableCell>
                      <TableCell>Medium, Large</TableCell>
                      <TableCell>Medium, Large</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>180-188cm</TableCell>
                      <TableCell>81-89cm</TableCell>
                      <TableCell>54-58cm</TableCell>
                      <TableCell>54-58cm</TableCell>
                      <TableCell>54-58cm</TableCell>
                      <TableCell>Large, XL</TableCell>
                      <TableCell>Large</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>185-196cm</TableCell>
                      <TableCell>84-91cm</TableCell>
                      <TableCell>56-58cm</TableCell>
                      <TableCell>58cm</TableCell>
                      <TableCell>56-58cm</TableCell>
                      <TableCell>XL</TableCell>
                      <TableCell>XL</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Grid container xs={12} md={12} className={classes.illMainGrid}>
                  <Typography className={classes.sizeTableHeading}>
                    Kako izmjeriti unutarnju duljinu nogu?
                  </Typography>
                  <Typography className={classes.illText}>
                    Mjerenje unutarnje duljine nogu (UDN ili "inseam") jedna je
                    od najtočnijih mjera za određivanje visine sjedala na
                    biciklu ili izbor veličine okvira bicikla.{" "}
                  </Typography>
                  <Grid item xs={9} md={10}>
                    <ul className={classes.illUnorderedList}>
                      <li className={classes.illList}>
                        Obucite hlače u kojima vozite bicikl
                      </li>
                      <li className={classes.illList}>
                        Bosi stanite uspravno uz zid
                      </li>
                      <li className={classes.illList}>
                        Knjigu tvrdih korica stavite među noge i podignite kao
                        da sjedite na sjedalu bicikla
                      </li>
                      <li className={classes.illList}>
                        Pomoćnik neka vam izmjeri udaljenost vrha knjige od poda
                      </li>
                      <li className={classes.illList}>
                        Dobivena visina je vaša unutarnja duljina noge (UDN)
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <img
                      src={udnImage}
                      alt="UDN"
                      style={{ height: "auto", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {post.frontmatter.sizeTableMTB && (
              <Grid item xs={12} md={12} className={classes.sizeTableGrid}>
                <Typography className={classes.sizeTableHeading}>
                  Odabir veličine: {post.frontmatter.sizeTableMTB}
                </Typography>
                <Table className={classes.sizeTable}>
                  <TableHead className={classes.sizeTableHead}>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Visina vozača
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Unutarnja duljina nogu
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Hardtail
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Fullsuspension
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Kona
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Superior
                    </TableCell>
                    <TableCell width="1%" className={classes.sizeTableHeadCell}>
                      Drag
                    </TableCell>
                  </TableHead>
                  <TableBody className={classes.sizeTableBody}>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>144-155cm</TableCell>
                      <TableCell>61-69cm</TableCell>
                      <TableCell>XS</TableCell>
                      <TableCell>XS</TableCell>
                      <TableCell>Small</TableCell>
                      <TableCell>Small</TableCell>
                      <TableCell>Small</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>150-160cm</TableCell>
                      <TableCell>66-74cm</TableCell>
                      <TableCell>Small</TableCell>
                      <TableCell>Small</TableCell>
                      <TableCell>Small</TableCell>
                      <TableCell>Small</TableCell>
                      <TableCell>Small</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>155-170cm</TableCell>
                      <TableCell>69-76cm</TableCell>
                      <TableCell>Small, Medium</TableCell>
                      <TableCell>Small, Medium</TableCell>
                      <TableCell>Small, Medium</TableCell>
                      <TableCell>Small, Medium</TableCell>
                      <TableCell>Small, Medium</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>165-180cm</TableCell>
                      <TableCell>74-79cm</TableCell>
                      <TableCell>Medium</TableCell>
                      <TableCell>Medium</TableCell>
                      <TableCell>Medium</TableCell>
                      <TableCell>Medium</TableCell>
                      <TableCell>Medium</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>175-185cm</TableCell>
                      <TableCell>76-84cm</TableCell>
                      <TableCell>Medium, Large</TableCell>
                      <TableCell>Medium, Large</TableCell>
                      <TableCell>Medium, Large</TableCell>
                      <TableCell>Medium, Large</TableCell>
                      <TableCell>Medium, Large</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>180-190cm</TableCell>
                      <TableCell>81-89cm</TableCell>
                      <TableCell>Large, XL</TableCell>
                      <TableCell>Large, XL</TableCell>
                      <TableCell>Large, XL</TableCell>
                      <TableCell>Large, XL</TableCell>
                      <TableCell>Large, XL</TableCell>
                    </TableRow>
                    <TableRow className={classes.sizeTableRow}>
                      <TableCell>185-200cm</TableCell>
                      <TableCell>84-91cm</TableCell>
                      <TableCell>Large, XL</TableCell>
                      <TableCell>Large, XL</TableCell>
                      <TableCell>Large, XL</TableCell>
                      <TableCell>Large, XL</TableCell>
                      <TableCell>Large, XL</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Grid container xs={12} md={12} className={classes.illMainGrid}>
                  <Typography className={classes.sizeTableHeading}>
                    Kako izmjeriti unutarnju duljinu nogu?
                  </Typography>
                  <Typography className={classes.illText}>
                    Mjerenje unutarnje duljine nogu (UDN ili "inseam") jedna je
                    od najtočnijih mjera za određivanje visine sjedala na
                    biciklu ili izbor veličine okvira bicikla.{" "}
                  </Typography>
                  <Grid item xs={9} md={10}>
                    <ul className={classes.illUnorderedList}>
                      <li className={classes.illList}>
                        Obucite hlače u kojima vozite bicikl
                      </li>
                      <li className={classes.illList}>
                        Bosi stanite uspravno uz zid
                      </li>
                      <li className={classes.illList}>
                        Knjigu tvrdih korica stavite među noge i podignite kao
                        da sjedite na sjedalu bicikla
                      </li>
                      <li className={classes.illList}>
                        Pomoćnik neka vam izmjeri udaljenost vrha knjige od poda
                      </li>
                      <li className={classes.illList}>
                        Dobivena visina je vaša unutarnja duljina noge (UDN)
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <img
                      src={udnImage}
                      alt="UDN"
                      style={{ height: "auto", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* Product bottom flex text */}

            <Grid container xs={12} md={12} className={classes.infoContainer}>
              <Grid
                item
                xs={12}
                md={4}
                align="center"
                className={classes.infoGrid}
              >
                <ShoppingCartOutlined
                  fontSize="large"
                  className={classes.iconWebshop}
                />
                <Typography className={classes.infoHeading}>
                  Zatraži ponudu od 0-24h
                </Typography>
                <AccessTimeOutlined fontSize="large" />
                <Typography className={classes.infoText}>
                  Ponudu možeš zatražiti svaki dan od 0 do 24 sata, bilo koji
                  dan u tjednu, svaki tjedan u mjesecu i svaki mjesec u godini.
                </Typography>
                <AccountBalanceOutlined fontSize="large" />
                <Typography className={classes.infoText}>
                  Sve cijene su iskazane u Eurima i uključuju PDV.
                </Typography>
                <BeenhereOutlined fontSize="large" />
                <Typography className={classes.infoText}>
                  Svi paketi koji se šalju su zaštičeni i zapakirani kako bi se
                  izbjegla potencijalna oštećenja prilikom transporta.
                </Typography>
                <WeekendOutlined fontSize="large" />
                <Typography className={classes.infoText}>
                  Naruči iz udobnosti svog doma!
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                align="center"
                className={classes.infoGrid}
              >
                <AddCircleOutlineOutlined
                  fontSize="large"
                  className={classes.iconOrder}
                />
                <Typography className={classes.infoHeading}>
                  Kako zatražiti ponudu?
                </Typography>
                <AddCircleOutlineOutlined fontSize="large" />
                <Typography className={classes.infoText}>
                  Artikl se dodaje u košaricu pritiskom na bijelu okruglu tipku
                  u donjem desnom uglu zaslona. Nakon što su dodani svi artikli
                  u košaricu, potrebno je prtisnuti crnu okruglu tipku također u
                  donjem desnom uglu zaslona te ostaviti podatke za slanje
                  ponude/narudžbe.
                </Typography>
                <InfoOutlined fontSize="large" />
                <Typography className={classes.infoText}>
                  Proizvodi se naručuju odabirom, korištenjem menija i
                  popunjavanjem elektronskog formulara za zahtjevom za ponudom.
                  Proizvod se smatra naručenim kada kupac prođe cijeli postupak
                  zahtjeva za ponudom i pritisne tipku "pošalji".
                </Typography>
                <BusinessOutlined fontSize="large" />
                <Typography className={classes.infoText}>
                  Izrada R1 ponude i računa na tvrtke - prilikom slanja zahtjeva
                  za ponudom, u napomenu je potrebno navesti podatke o firmi
                  (naziv, adresa i OIB).
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                align="center"
                className={classes.infoGrid}
              >
                <LocalShippingOutlined
                  fontSize="large"
                  className={classes.iconShipping}
                />
                <Typography className={classes.infoHeading}>
                  Plaćanje i dostava
                </Typography>
                <LocalShippingOutlined fontSize="large" />
                <Typography className={classes.infoText}>
                  Dostava je dostupna na teritoriju cijele Republike Hrvatske.
                </Typography>
                <MoneyOutlined fontSize="large" />
                <Typography className={classes.infoText}>
                  Cijena dostave za sve dijelove i opremu je 7,00 € bez obzira
                  na količinu proizvoda i/ili broj paketa, a za bicikle je 25,00
                  € po biciklu, osim regije Dubrovnik i mjesta s poštanskim
                  brojem 20xxx gdje je cijena dostave 30,00 € po biciklu.
                </Typography>
                <AccountBalanceWalletOutlined fontSize="large" />
                <Typography className={classes.infoText}>
                  Narudžbe je moguće platiti uplatnicom, odnosno virmanom te
                  internet ili mobilnim bankarstvom na naš IBAN po ponudi ili
                  gotovinom prilikom dostave (pouzećem do maksimalnih 200,00 €).
                  Za plaćanje pouzećem zaračunava se usluga plaćanja pouzećem
                  koja iznosi 2,00 €.
                </Typography>
              </Grid>
            </Grid>
            <Typography align="center" className={classes.dislaimer}>
              Sve cijene na stranici su iskazane u Eurima (€) i uključuju porez
              na dodanu vrijednost (PDV). Trudimo se biti što točniji u opisu
              proizvoda, prikazu slika i cijena, ali ne možemo garantirati da su
              sve informacije 100% ispravne i bez grešaka. Svi artikli navedeni
              na stranici su dio naše ponude što ne znači da su dostupni u
              svakom trenutku. Za točnu provjeru stanja, molimo da nas
              kontaktirate putem e-maila ili telefonskog poziva.
            </Typography>
          </Grid>
          <AddToBasket item={product} />
        </>
      )}

      {/* Standard page */}
      {post.frontmatter.page === "home" && (
        <div>
          <Typography className={classes.notice} align="center" variant="body1">
            {" "}
            <span
              role="img"
              aria-label="pin ikona"
              className={classes.noticeIcon}
            >
              📌
            </span>
            <p>Kolektivni godišnji odmor od 23.12.2024. do 6.1.2025.</p>
            <p>Prvi radni dan je utorak 7.1.2025.</p>
          </Typography>
          <Carousel className={classes.crsl} />
        </div>
      )}
      {!post.frontmatter.price && renderAst(post.htmlAst)}

      {/* Sub pages list */}
      {!post.frontmatter.price && !post.frontmatter.page && (
        <Grid
          spacing={0}
          container
          justify="center"
          className={classes.pageItem}
        >
          {subpages.map(s => (
            <PageItem
              key={s.fields.slug}
              title={s.frontmatter.title}
              description={s.frontmatter.description}
              page={s.frontmatter.page}
              image={
                s.frontmatter.image
                  ? s.frontmatter.image.childImageSharp.fluid.src
                  : ""
              } // default slika
              slug={s.fields.slug}
            />
          ))}
        </Grid>
      )}

      {/* Product list */}
      <Grid
        className={classes.productListGrid}
        spacing={0}
        container
        direction="row"
        alignItems="center"
        justify="center"
      >
        {products.map(p => (
          <ProductItem
            key={p.fields.slug}
            slug={p.fields.slug}
            title={p.frontmatter.title}
            price={p.frontmatter.price}
            priceBefore={p.frontmatter.priceBefore}
            emblem={p.frontmatter.emblem}
            specialDeal={p.frontmatter.specialDeal}
            available={p.frontmatter.available}
            brand={p.frontmatter.brand}
            image={
              p.frontmatter.image
                ? p.frontmatter.image.childImageSharp.fluid.src
                : ""
            } // default slika
            image1={
              p.frontmatter.image1
                ? p.frontmatter.image1.childImageSharp.fluid.src
                : ""
            }
          />
        ))}
      </Grid>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $subpageregex: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      htmlAst
      fields {
        slug
      }
      frontmatter {
        title
        description
        page
        sku
        sizeTableMTB
        sizeTableCTB
        price
        priceBefore
        last30days
        emblem
        extraDiscount
        specialDeal
        available
        orderable
        brand
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1280) {
              src
              srcSet
              ...GatsbyImageSharpFluid
            }
          }
        }
        image1 {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1280) {
              src
              srcSet
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2 {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1280) {
              src
              srcSet
              ...GatsbyImageSharpFluid
            }
          }
        }
        image3 {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1280) {
              src
              srcSet
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { fields: { slug: { regex: $subpageregex } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          page
          order
          price
          extraDiscount
          specialDeal
          available
          emblem
          priceBefore
          brand
          image {
            childImageSharp {
              fluid(maxWidth: 1280) {
                src
              }
            }
          }
          image1 {
            childImageSharp {
              fluid(maxWidth: 1280) {
                src
              }
            }
          }
        }
      }
    }
  }
`
