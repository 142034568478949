import winterspecial from "./winterspecialland.jpg"
import cratoni from "./cratoni-land.jpg"
import mucoff from "./mucoff-land.jpg"
import magura from "./magura-land.jpg"

const slider = [
  {
    id: 1,
    image: winterspecial,
    title: "Winter Special",
    description: "Cijene bicikala pale kao temperatura u gradu.",
    description1: "",
    href: "./bicikli/",
    button: "Pogledaj ponudu",
  },
  {
    id: 2,
    image: cratoni,
    title: "Cratoni",
    description:
      "Specijalist za inovativnost biciklističkih kaciga. Sigurnost potvrđena.",
    href: "./garderoba/kacige/",
    button: "Pogledaj ponudu",
  },
  {
    id: 3,
    image: mucoff,
    title: "Muc-Off",
    description:
      "Clean, Protect, Lube. Sredstva koja čine tvog dvokotačnog ljubimca sretnim.",
    href: "/odrzavanje/sredstva-za-odrzavanje/",
    button: "Pogledaj ponudu",
  },
  {
    id: 4,
    image: magura,
    title: "Magura",
    description: "Kočnice koje osvajaju naslove. Zaustavi se na vrijeme.",
    href: "/dijelovi/kocnice/",
    button: "Pogledaj ponudu",
  },
]

export default slider
